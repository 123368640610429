<template>
  <div>
    <pwd-contair>
      <div slot="menu">账户变动-消费记录</div>
      <div slot="pwdContair" style="min-height: 400px">
        <div>
          <consumption-form :formData="dataSource"></consumption-form>
        </div>
        <div class="floatR Mtp20">
          <el-pagination
            background
            :total="ipagination.totalCount"
            :page-size="ipagination.size"
            :current-page="ipagination.current"
            @size-change="onSizeChange"
            @current-change="onPageChange"
            layout="total, sizes, prev, pager, next"
          ></el-pagination>
        </div>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import consumptionForm from "@/components/purchaser/consumptionForm.vue";
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import { findUserTopUpOrConsumeList } from "@/api/user";
import { listMixin } from "@/mixins/listMixins";
import { mapGetters } from "vuex";
export default {
  components: { consumptionForm, pwdContair },
  mixins: [listMixin],
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      extraParam: {
        userId: '',
        type: 2
      },
      url: {
        list: findUserTopUpOrConsumeList
      }
      // formData: [
      //   {
      //     changeTime: "11",
      //     orderId: '',
      //     money:'',
      //   },
      // ],
    };
  },
  mounted() {
    this.extraParam.type = 2
    this.extraParam.userId = this.userInfo.id;
    this.loadData()
  },
  // methods: {
  //     getRechargeDate() {
  //     findUserTopUpOrConsumeList(
  //       2,
  //       this.userInfo.id,
  //       this.ipagination.current,
  //       this.ipagination.size
  //     ).then((res) => {
  //       if (res.success) {
  //         this.$message.success("获取数据成功");
  //         this.formData = res.data.records;
  //         console.log(res.data.records);
  //       } else {
  //         this.$message.error(res.msg);
  //       }
  //     });
  //   },
  // }
};
</script>
<style lang="scss" scoped></style>
