<!--
 * @Author: Jerry
 * @Date: 2021-01-31 10:27:05
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-17 22:56:50
 * @Description: file content
-->
<template>
  <div>
    <el-table
      :header-cell-style="{ background: '#f4f4f4' }"
      :data="formData"
      style="width: 100%"
    >
      <el-table-column prop="changeTime" label="时间" width="200">
        <template slot-scope="scope">
          {{ formatDate(scope.row.changeTime * 1000) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="orderNo" label="订单号">
      </el-table-column>
      <el-table-column align="center" prop="money" label="消费金额">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <div class="btn Mtp5">
            <el-button type="text" size="mini" @click="checkOrder(scope.row.orderId)">查看订单</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { formatDate } from "@/util/util";
export default {
  props: {
    formData: {
      type: Array,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    formatDate,
    checkOrder(orderId) {
      this.$router.push({
        name: 'OrderDetail',
        query: {
          orderId
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>

</style>
